import moment from 'moment';
import { Moment } from 'moment';
import {
  PENDING_APPROVAL,
  PUBLISHING_IN_PROGRESS,
  PUBLISHING_FAILED,
} from '../lang/en';

import {
  BADGECOLOR,
  BUNDESLIGA,
  CARDSTATE,
  CATEGORY_WIDGET_ID_MAP,
  CURATION_REASON_LIST,
  designerImagePath,
  fullImagePath,
  IPL_LEAGUE,
  PUBLISHER_TYPES,
  SCREEN_CHAR_LIMIT_SPECS,
  SPACES_ENABLED_REGIONS,
  UTM_IN_URL_ERROR_DESCRIPTION,
  ZAPP_WIDGET_IDS_COLLECTIONS_MAP,
} from '../constants';
import { UserData } from '../../../src/types';
import { Env, Regions } from '../enums';
import { INTERNAL_ROLES } from '../constants';
import { showNotification } from './notifications';
import { SPORTS_SPACE } from '../lang/spaces/en';
import { LEAGUE_TYPES } from '../enums';
import { toInteger } from 'lodash-es';
import { SpaceConfigType } from '../types';
import { SportsLeagueType } from '../types/Spaces-v1';
import leagueData from '../constants/spaces-v1/leaguesData.json';

export const getBadgeColor = (state: string): string => {
  if (state === PENDING_APPROVAL) {
    return BADGECOLOR['pendingApproval'];
  } else if (state === PUBLISHING_IN_PROGRESS) {
    return BADGECOLOR['publishingInProgress'];
  } else if (state === PUBLISHING_FAILED) {
    return BADGECOLOR['publishingFailed'];
  } else {
    return BADGECOLOR[state];
  }
};

export const devEnvs = [Env.DEV, Env.LOCAL];

export const getEnvDetails = () => {
  if (devEnvs.some((el) => window.location.hostname.includes(el))) {
    return Env.DEV;
  }
  if (window.location.hostname.includes(Env.STAGING)) {
    return Env.STAGING;
  }
  return Env.PROD;
};

export const isNonProdEnv = () => {
  const env = getEnvDetails();
  return env === Env.STAGING || env === Env.DEV;
};

export const getFastCardDeepLink = (cardId: string) => {
  const trendsURL = isNonProdEnv()
    ? `http://trends.staging.glance.com`
    : `https://trends.glance.com`;
  return `${trendsURL}/newz/fast-cards?utm_medium=$UTM_MEDIUM&glanceId=$GLANCE_ID&gl_imp_id=$IMP_ID&userId=$USER_ID&apiKey=$API_KEY&sdkv=$BUILD_VERSION&gpid=$GPID&category=for_you&cardPressId=${cardId}`;
};

export const getCTAUrlForWebVideo = (
  cardId: string,
  region: string,
  language: string,
) => {
  const trendsURL = isNonProdEnv()
    ? `http://trends.staging.glance.com`
    : `https://trends.glance.com`;
  return `${trendsURL}/newz/video-lite/${region}/${language}/${cardId}`;
};

export const getStudioDraftLink = (draftId: string) => {
  const studioEnv = getEnvDetails();
  const studioURL =
    studioEnv === 'staging' || studioEnv === 'dev' ? `-${studioEnv}` : '';

  return draftId
    ? `https://studio${studioURL}.glance.inmobi.com/#/moderator/drafts/draftIds/${draftId}`
    : '#';
};
// TODO: v1.1 using cdnDomain from context instead of this fn, remove it later
// export const getImagePath = () => {
//   const devEnvs = ['dev', 'localhost', 'staging'];
//   const gcpCdnName = devEnvs.some((el) => window.location.hostname.includes(el))
//     ? GCP_CDN_STAGE
//     : GCP_CDN_PROD;
//   return gcpCdnName;
// };

export const isInternalPublisher = (usersData: UserData): boolean => {
  let isInternalPub = false;
  if (usersData?.users?.length > 0) {
    isInternalPub = !!usersData?.users?.find(
      (user) => INTERNAL_ROLES.indexOf(user?.role) !== -1,
    );
  }
  return isInternalPub;
};

export const getCountryCode = (): Regions => {
  const hostName = window.location.hostname;
  if (hostName.indexOf('-') > -1) {
    const { 0: name } = hostName.split('-');
    return name.length === 2 ? (name.toUpperCase() as Regions) : Regions.IN;
  }
  return Regions.IN;
};

export const isEnableBeautifulWallpaper = getEnvDetails() !== Env.PROD;
export const imagePath = (image: string, cardId: string) =>
  image?.indexOf('upload') > -1
    ? designerImagePath(cardId, image)
    : fullImagePath(image);

export const showOTDByState = (state?: string) =>
  Boolean(
    state === CARDSTATE.pendingApproval.key || state === CARDSTATE.draft.key,
  );

export const convertDateTimetoSeconds = (dateTime: string): string => {
  return moment(dateTime).utc().unix().toString();
};

export const getYearMonthDate = (date = '') => {
  let localeDate = new Date(date);
  const offset = localeDate.getTimezoneOffset();
  localeDate = new Date(localeDate.getTime() - offset * 60 * 1000);
  return localeDate.toISOString().split('T')[0];
};

export const charLimit = (text: string, type: string, screen: string) => {
  return text.substring(
    0,
    SCREEN_CHAR_LIMIT_SPECS[type as keyof typeof SCREEN_CHAR_LIMIT_SPECS][
      screen as keyof typeof SCREEN_CHAR_LIMIT_SPECS.Roundup
    ],
  );
};

export const getCurrentTimeInEst = (date: Date) => {
  return date.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    timeStyle: 'short',
  });
};

export const isUrlValidWithCurationReason = (
  ctaUrl: string,
  curationReason: string,
) => {
  const lpValue = CURATION_REASON_LIST.filter((item) => {
    if (item.key === 'LANDING_PAGE_APPEND_UTM') return item.value;
  });
  if (curationReason && ctaUrl && lpValue[0].value === curationReason) {
    const utm_param_in_url = decodeURIComponent(ctaUrl)
      .substr(1)
      .split('&')
      .filter((params) => params.includes('utm_'));

    if (utm_param_in_url.length > 0) {
      showNotification('error', {
        message: 'Error',
        description: UTM_IN_URL_ERROR_DESCRIPTION,
      });
      return false;
    }
  }
  return true;
};

export const convertUnixToLocalTime = (initialDateTime: number) => {
  return moment.unix(initialDateTime);
};

export const convertLocalTimeToUnix = (dateAndTime: Moment | null) => {
  return dateAndTime?.clone().utc().unix();
};

//Safety check for old img - Removing extra slash generated from DS service in relative URL
export const getImgUrl = (cdnDomainHost: string, relImgUrl?: string) => {
  if (relImgUrl) {
    const relUrl = relImgUrl.charAt(0) === '/' ? relImgUrl.slice(1) : relImgUrl;
    return cdnDomainHost + relUrl;
  }
  return cdnDomainHost;
};

export const getSelectedSpacesPage = (pathname: string) => {
  let selectedPage;
  switch (pathname) {
    case '/spaces/home':
      selectedPage = 'all-stories';
      break;
    case '/spaces/trend-management':
      selectedPage = 'trend-management';
      break;
    case '/spaces/collections':
      selectedPage = 'collections';
      break;
    default:
      selectedPage = 'roundup';
      break;
  }
  return selectedPage;
};

// This function verifies whether the videoUrl is a valid YouTube URL and conforms to the proper format
export const isYoutubeUrl = (videoURL: string) => {
  const regexToIdentifyYoutubeVideos =
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
  return regexToIdentifyYoutubeVideos.test(videoURL);
};

// In case of YouTube videos, react-player's onDuration function returns 1 second
// more than the actual video duration. Hence adjusting the duration accordingly.
export const getAdjustedVideoDuration = (duration: number) =>
  Math.floor(duration - 1);

export const isOcpEnabled = () => {
  const countryCode = getCountryCode();
  return countryCode === Regions.IN || countryCode === Regions.ID;
};

export const getPreviewImageUrl = (
  cdnPrefix: string,
  imageUrl: string | undefined,
  emptyImage: string,
) => {
  if (imageUrl !== cdnPrefix) {
    return imageUrl;
  } else {
    return emptyImage;
  }
};

export const isSpacesEnabledRegion = () =>
  SPACES_ENABLED_REGIONS.includes(getCountryCode());

// TODO: Add Category Zapp Widgets Ids for multiple space selection later
export const getZappWidgetIds = (
  spaces: Array<string>,
  isBreakingNews: boolean,
  spaceConfig: SpaceConfigType,
  categoryId?: string,
  league?: string,
  publisherType?: string,
) => {
  const zappWidgetIds = [
    ...(spaceConfig.NEWS?.zappWidgetIds?.LOCAL_NEWS || []),
  ];
  const isNationalPublisher =
    publisherType?.toLocaleLowerCase() !== PUBLISHER_TYPES.REGIONAL;
  const categoryWidget = getCategoryZappWidgetIds(categoryId || '');
  if (spaces.includes(SPORTS_SPACE) && league) {
    if (league === BUNDESLIGA.toLowerCase()) {
      zappWidgetIds.push(
        ...(spaceConfig.SPORTS?.zappWidgetIds?.BUNDESLIGA || []),
      );
    } else if (league === IPL_LEAGUE.toLowerCase()) {
      zappWidgetIds.push(...(spaceConfig.SPORTS?.zappWidgetIds?.IPL || []));
    } else {
      zappWidgetIds.push(
        ...(spaceConfig.SPORTS?.zappWidgetIds?.[
          LEAGUE_TYPES[toInteger(league)] as SportsLeagueType
        ] || []),
      );
    }
  } else {
    if (isBreakingNews) {
      zappWidgetIds.push(
        ...(spaceConfig.NEWS?.zappWidgetIds?.BREAKING_NEWS || []),
      );
    } else {
      !!categoryWidget
        ? zappWidgetIds.push(
            ...[
              ...((isNationalPublisher &&
                spaceConfig.NEWS?.zappWidgetIds?.TOP_STORIES) ||
                []),
              categoryWidget,
            ],
          )
        : zappWidgetIds.push(
            ...((isNationalPublisher &&
              spaceConfig.NEWS?.zappWidgetIds?.TOP_STORIES) ||
              []),
          );
    }
  }
  return zappWidgetIds;
};

export const getZappIdOptions = (spacesOption: Array<string>) =>
  ZAPP_WIDGET_IDS_COLLECTIONS_MAP.filter((item) =>
    spacesOption.includes(item.label),
  ) || [];

export const getSpacesDeeplink = (
  collectionId: string,
  articleId: string,
  zappId: string,
) => {
  return `glance://space.zapp/newsZapp?source=EXPLORE&destination=article_collection&zappWidgetId=${zappId}&collectionId=${collectionId}&articleId=${articleId}`;
};

export const getLocalDateString = (time?: number | Date | string) => {
  return time ? new Date(time).toLocaleString() : null;
};

export const getL0ZappWidgetIds = (
  isBreakingNews: boolean,
  spaceConfig: SpaceConfigType,
  isSportsSpaceSelected = false,
) => {
  if (isSportsSpaceSelected) {
    return spaceConfig.SPORTS.l0ZappWidgetIds.SPORTS;
  } else if (isBreakingNews) {
    return spaceConfig.NEWS.l0ZappWidgetIds.BREAKING_NEWS;
  } else {
    // Top News l0ZappWidgetIds
    return spaceConfig.NEWS.l0ZappWidgetIds.TOP_STORIES;
  }
};

export const getCategoryZappWidgetIds = (categoryId: string) => {
  const categoryWidgetMap = isNonProdEnv()
    ? CATEGORY_WIDGET_ID_MAP.STAGE
    : CATEGORY_WIDGET_ID_MAP.PROD;
  let categoryWidgetId;
  for (const key in categoryWidgetMap) {
    if (
      categoryWidgetMap[key as keyof typeof categoryWidgetMap].includes(
        categoryId,
      )
    ) {
      categoryWidgetId = key;
      break;
    }
  }
  return categoryWidgetId;
};

export const getImageUrl = (url: string, cdnDomain = '') => {
  if (url?.includes(cdnDomain)) {
    return url;
  }
  return `${cdnDomain}${url}`;
};

export const getValueProp = (
  valueProp: string | undefined | null,
  region: Regions,
) => {
  if (!valueProp) {
    return null;
  }
  return [
    {
      vpId: valueProp,
      region,
    },
  ];
};

export const getLeaguesByRegion = (region: Regions) => {
  if (region === Regions.IN) {
    return [
      {
        leagueId: IPL_LEAGUE.toLowerCase(),
        abbreviation: IPL_LEAGUE,
        name: IPL_LEAGUE,
      },
    ];
  }
  const leagues = leagueData.data.slice();
  if (region === Regions.DE || region === Regions.AT) {
    leagues.push({
      leagueId: BUNDESLIGA.toLowerCase(),
      abbreviation: BUNDESLIGA,
      name: BUNDESLIGA,
    });
  }
  return leagues;
};
